<template>
  <el-button
    ref="btn"
    :icon="showWarning ? 'fa fa-exclamation-circle' : icon"
    :plain="!showWarning"
    :size="size"
    type="danger"
    @blur.native="showWarning = false"
    @keydown.native.esc="showWarning = false"
    @click="handleClick"
  >
    <slot v-if="!showWarning" /> <span v-else> Click to Confirm </span>
  </el-button>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: '' },
    size: { type: String, default: '' },
  },
  data() {
    return {
      showWarning: false,
    };
  },
  methods: {
    handleClick() {
      if (!this.showWarning) {
        this.showWarning = true;
      } else {
        this.showWarning = false;
        this.$emit('confirm');
      }
    },
  },
};
</script>

<style></style>
