<template>
  <div
    v-if="selectedItems.length > 1"
    style="position: relative"
  >
    <div
      class="selection-count"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      {{ selectedItems.length }} items selected
    </div>
    <div :class="itemListClass">
      <el-card>
        <div
          v-for="item in selectedItems.slice(0, 25)"
          :key="item.id"
          class="item"
        >
          <div>
            <i class="fa fa-check" />
          </div>
          <div>
            {{ labelize(item) }}
          </div>
        </div>
        <div v-show="selectedItems.length > 25">
          <el-alert
            :closable="false"
            type="warning"
          >
            {{ selectedItems.length - 25 }} more records not shown
          </el-alert>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: Array, default: () => ['$name'] },
    selectedItems: { type: Array, default: () => [] },
  },

  data() {
    return {
      showList: false,
    };
  },

  computed: {
    itemListClass() {
      return {
        'item-list': true,
        visible: this.showList,
      };
    },
  },

  methods: {
    handleMouseEnter() {
      this.showList = true;
    },

    handleMouseLeave() {
      this.showList = false;
    },

    labelize(item) {
      let label = '';
      this.label.map(part => {
        if (part.charAt(0) === '$') {
          label += item[part.substring(1)] ? item[part.substring(1)] : '';
        } else {
          label += part;
        }
      });

      return label;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.selection-count {
  background: $--color-primary;
  border-radius: 14px;
  color: #fff;
  cursor: default;
  font-size: 0.8em;
  padding: 4px 8px;
}

.item-list {
  background-color: #fff;
  font-size: 0.8em;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 30px;
  transition: opacity 0.3s ease;
  width: 450px;
  z-index: 5;

  &.visible {
    opacity: 1;
  }

  .item {
    display: flex;

    .fa {
      margin-right: 0.5em;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.5em;
    }
  }
}
</style>
