<template>
  <div class="search-box-container">
    <help-tip class="help">
      <p>
        You can enter simple search terms such as:
        <pre>blue basket</pre>
        which will search for all items containing "blue" <em>and</em> "basket".
        You can also use a more complex boolean search, for example:
        <pre>(blue OR red) AND basket AND NOT retro</pre>
        which will search for items containing "basket" and either "blue" or
        "red", but excluding items matching "retro".
      </p>
      <p>
        It's also possible to search for exact phrases, by using quotes:
        <pre>"corner store"</pre>
        which would match "Corner Store" but not "Store Corner" or "Corner by the Store".
      </p>
      <p>
        Finally, you can use wildcards to partially match words, e.g.:
        <pre>home* AND parlor</pre>
        would match "Parlor Home", "Home9 Parlor", and "Home22 from Parlor".
      </p>
    </help-tip>
    <el-input
      v-model="searchText"
      class="search-box"
      :spellcheck="false"
      prefix-icon="fa fa-search"
      clearable
      @keyup.native.enter="search"
      @keyup.native.esc="e => { e.target.blur(); clear() }"
      @clear="clear"
    />
  </div>
</template>

<script>
import HelpTip from './HelpTip.vue';

export default {
  components: { HelpTip },
  data() {
    return {
      searchText: '',
    };
  },
  mounted() {
    const query = this.$route.query.search;

    if (query) {
      this.searchText = query;
      this.search();
    }
  },
  methods: {
    search() {
      this.$emit('search', this.searchText);
      this.$router.replace({ query: { search: this.searchText } });
    },
    clear() {
      this.searchText = '';
      this.$router.replace({ query: { search: null } });
      this.$emit('search', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box-container {
  align-items: center;
  display: flex;
}

.help {
  margin-right: 0.4em;
}

.search-box {
  min-width: 180px;
}
</style>
