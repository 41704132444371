<template>
  <div
    :style="spinnerStyle"
    class="custom-spinner"
  >
    <svg
      class="circular"
      viewBox="25 25 50 50"
    >
      <circle
        :r="Math.min(size, 12)"
        class="path"
        cx="50"
        cy="50"
        fill="none"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    bottom: { type: String, default: 'inherit' },
    left: { type: String, default: 'inherit' },
    right: { type: String, default: 'inherit' },
    size: { type: Number, default: 12 },
    static: { type: Boolean, default: false },
    top: { type: String, default: 'inherit' },
  },

  computed: {
    spinnerStyle() {
      let style = `left: ${this.left}; top: ${this.top}; right: ${
        this.right
      }; bottom: ${this.bottom};`;

      if (this.static) style += ' position: static;';

      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.custom-spinner {
  position: absolute;

  .circular {
    height: 36px;
    width: 36px;
    animation: loading-rotate 2s linear infinite;
    position: relative;
  }

  .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: $--color-primary;
    stroke-linecap: round;
  }
}
</style>
