<template>
  <div>
    <div
      class="top-controls"
      :style="(doubleHeader ? 'flex-direction:column' : 'flex-direction:row-reverse')"
    >
      <div>
        <div class="controls">
          <div
            v-if="!disableSearch"
          >
            <search-box @search="search" />
          </div>
          <slot
            :archive-view="archiveView"
            :selected-item-count="selectedItems.length"
            name="additional-controls"
          />
        </div>
      </div>
      <div class="pagination-row">
        <div v-if="multiplePages">
          <el-pagination
            :current-page="pageNum"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            :total="totalItems"
            layout="prev, pager, jumper, next, sizes"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </div>
        <div class="flex-row">
          <selection-count :selected-items="selectedItems" />
        </div>
        <div style="width:80px;height:24px;">
          <div v-show="busy">
            <positional-spinner
              right="0"
              static
              top="0"
            />
          </div>
        </div>
      </div>
    </div>

    <el-table
      ref="elTable"
      :data="items"
      :default-sort="{ prop: defaultSort.sortBy, order: defaultSort.order }"
      :highlight-current-row="true"
      :row-class-name="rowClassName"
      row-key="id"
      size="small"
      stripe
      @selection-change="selectionChange"
      @sort-change="sortChange"
    >
      <el-table-column
        v-if="!disableCheckboxes"
        reserve-selection
        type="selection"
        width="55"
      />
      <slot />
      <el-table-column
        v-if="menuActions.length > 0"
        width="120"
      >
        <template slot-scope="{ row }">
          <action-menu
            :actions="menuActions"
            :archive-view="archiveView"
            :show-archive-restore="
              !disableArchive &&
                (archiveView ? hasRestorePerms : hasArchivePerms)
            "
            :item="row"
            @action="action => handleMenuAction(action, row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="!disableArchive && hasRestorePerms"
      class="view-archive-link"
    >
      <el-switch
        v-model="archiveView"
        active-text="View Archived Items"
      />
    </div>
    <div
      v-if="multiplePages"
      class="bottom-pagination"
    >
      <el-pagination
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="totalItems"
        layout="prev, pager, jumper, next, sizes"
        @current-change="handlePageChange"
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ActionMenu from './ActionMenu.vue';
import PositionalSpinner from './PositionalSpinner.vue';
import SearchBox from './SearchBox.vue';
import SelectionCount from './SelectionCount.vue';

export default {
  name: 'DataTable',
  components: {
    ActionMenu,
    PositionalSpinner,
    SearchBox,
    SelectionCount,
  },
  props: {
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    disableArchive: { type: Boolean, default: false },
    findOptions: { type: Object, default: () => ({}) },
    permissionSuffix: { type: String, default: '' },
    dataClass: { type: Object, required: true },
    defaultSort: { type: Object, required: true },
    disableActionMenu: { type: Boolean, default: false },
    disableCheckboxes: { type: Boolean, default: false },
    disableSearch: { type: Boolean, default: false },
    doubleHeader: { type: Boolean, default: false },
    filters: { type: Object, default: null },
    paginationSize: { type: Number, default: null },
    rowClassName: { type: Function, default: null },
    startPage: { type: Number, default: 0 },
  },
  data() {
    return {
      archiveView: false,
      busy: false,
      direction: this.defaultSort.order,
      items: [],
      keyModifiers: [],
      pageNum: 1,
      pageSize: this.paginationSize
        ? this.paginationSize
        : this.$store.getters['user/paginationSize'],
      queryParams: {},
      searchQuery: '',
      selectedItems: [],
      sort: this.defaultSort.sortBy,
      totalItems: 0,
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission']),
    ...mapState('settings', ['options']),

    pageSizes() {
      return this.options['pagination:pageSizes'];
    },

    hasArchivePerms() {
      return (
        this.permissionSuffix &&
        (this.hasPermission(`archive_${this.permissionSuffix}`) || this.hasPermission(`administer_${this.permissionSuffix}`))
      );
    },

    hasRestorePerms() {
      return (
        this.permissionSuffix &&
        (this.hasPermission(`restore_${this.permissionSuffix}`) || this.hasPermission(`administer_${this.permissionSuffix}`))
      );
    },

    menuActions() {
      return !this.disableActionMenu ? this.actions : [];
    },

    multiplePages() {
      return this.totalItems > this.pageSize;
    },
  },

  watch: {
    archiveView(value) {
      this.pageNum = 1;
      this.$emit('archive', value);
      this.invokeQuery();
    },

    filters: {
      handler(value) {
        this.pageNum = 1;
        this.invokeQuery();
      },
      deep: true,
    },

    findOptions: {
      handler(value) {
        this.pageNum = 1;
        this.invokeQuery();
      },
      deep: true,
    },

    startPage(value) {
      this.handlePageChange(value);
    },
  },

  mounted() {
    this.invokeQuery();
  },

  methods: {
    changeSort({ prop, order }) {
      this.dataTable.sortBy = prop;
      this.dataTable.order = order;
    },

    clearSelection() {
      this.$refs.elTable.clearSelection();
    },

    async handleArchive(item) {
      try {
        await this.$confirm(
          'Are you sure you want to ' +
            `${this.archiveView ? 'restore' : 'archive'} "${item.name ? item.name : item.email}"?`,
          'Archive Item',
        );

        await this.dataClass.delete(
          item.id,
          this.archiveView ? { restore: true } : {},
        );
        window.mixpanel.track(
          `${this.archiveView ? 'Restore' : 'Archive'} Item: ${
            this.dataClass.name ? this.dataClass.name : this.dataClass.email
          }`,
          {
            ID: item.id,
            Name: item.name ? item.name : item.email,
          },
        );
        this.invokeQuery();
      } catch (e) {
        if (e !== 'cancel') {
          this.$reportError(e);
        }
      }
    },

    handleMenuAction(command, item) {
      if (command === 'archive') {
        this.handleArchive(item);
      } else {
        this.$emit('action', command, item);
      }
    },

    async invokeQuery(overrides = {}) {
      this.busy = true;

      try {
        const params = {
          ...this.findOptions,
          ...this.filters,
          page: this.pageNum || 1, // this.dataTable.currentPage,
          per_page: this.pageSize,
          search: this.searchQuery,
          sort: this.sort,
          direction: this.direction === 'ascending' ? 'asc' : 'desc',
          trashed: this.archiveView ? 'yes' : null,
          ...overrides,
        };
        const data = await this.dataClass.find(params);
        if (!data) return;

        this.items = data.data;
        this.totalItems = Number(data.total);
        this.$emit('data-fetch', data.data);
      } catch (e) {
        this.$notify.error({
          message:
            'There was a problem fetching the data. This issue has been reported.',
        });
        /*this.$reportError(e, {
          componentName: this.$options.name,
          modelName: this.dataClass.name,
        });*/
      } finally {
        this.busy = false;
      }
    },

    // Called when user changes pages
    handlePageChange(pageNum) {
      this.pageNum = pageNum;
      this.$store.dispatch('app/setLastPageNum', pageNum);

      try {
        this.$scrollTo(document.body);
      } catch (e) {
        // dumb
      }
      this.invokeQuery();
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.handlePageChange(1);
    },

    updateItemById(id, valueMap) {
      const item = this.items.find(i => i.id === id);

      if (!item) return;
      for (const key in valueMap) {
        item[key] = valueMap[key];
      }
    },

    search(query) {
      this.searchQuery = query.trim().split('-').join(' ');
      this.pageNum = 1;
      this.invokeQuery();
    },

    selectionChange(selections) {
      this.selectedItems = selections;
      this.$emit('selection-change', selections);
    },

    // Invoked when the user clicks a sort arrow on a table header
    async sortChange({ prop, order }) {
      /**
       * This gets called immediately when the table mounts, so we don't need
       * to fetch data in a mounted() lifecycle method.
       * PS: this only happens IF you pass a default sort/order!
       */
      if (!prop) return;

      this.sort = prop;
      this.direction = order;
      this.invokeQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$element';

.top-controls {
  align-items: start;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.pagination-row {
  display: flex;
  flex-direction: row;
}

.flex-row {
  align-items: center;
  display: flex;
}

.bottom-pagination {
  margin: $--main-padding 0;
}

.controls {
  display: flex;
  align-items: start;
  justify-content: end;
  flex-grow: 1;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.view-archive-link {
  margin-left: 20px;
  margin-top: 20px;
}
</style>
