<template>
  <i :class="iconClass" />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    iconClass() {
      return `icon fa fa-fw fa-${this.icon}`;
    },
  },
};
</script>

<style scoped>
.icon {
  margin-right: 6px;
}
</style>
