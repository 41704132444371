<template>
  <div v-if="mode === 'menu'">
    <el-dropdown
      trigger="click"
      @command="cmd => $emit('action', cmd)"
    >
      <el-button
        size="small"
        class="dropdown-button"
        icon="fa fa-ellipsis-v"
      />
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, index) in filteredActions">
          <el-dropdown-item
            v-if="!action.divider"
            :key="action.command"
            :disabled="action.disabled"
            :divided="(filteredActions[index - 1] || {}).divider"
            :class="menuItemClass(action)"
            :command="action.command"
          >
            <menu-icon :icon="action.icon" />
            {{ action.label }}
          </el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
  <div v-else-if="filteredActions.length > 0">
    <el-card
      class="action-card"
      :shadow="raised ? 'always' : 'never'"
    >
      <div
        v-for="(action, index) in filteredActions"
        :key="action.label"
        :class="buttonClass(action, filteredActions[index - 1])"
      >
        <template v-if="!action.divider">
          <el-button
            v-if="action.type !== 'danger'"
            :disabled="action.disabled"
            :icon="`fa fa-fw fa-${action.icon}`"
            plain
            type="primary"
            @click="$emit('action', action.command)"
          >
            {{ action.label }}
          </el-button>
          <confirm-button
            v-else
            :disabled="action.disabled"
            :icon="`fa fa-fw fa-${action.icon}`"
            @confirm="$emit('action', action.command)"
          >
            {{ action.label }}
          </confirm-button>
        </template>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MenuIcon from './MenuIcon.vue';
import ConfirmButton from './ConfirmButton.vue';

export default {
  components: { ConfirmButton, MenuIcon },
  props: {
    actions: { type: Array, required: true },
    archiveView: { type: Boolean, default: false },
    item: { type: Object, required: true },
    mode: { type: String, default: 'menu' },
    raised: { type: Boolean, default: false },
    showArchiveRestore: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters('user', ['hasPermission', 'isInternal']),

    filteredActions() {
      const actions = [
        ...this.actions
          .filter(action => !(action.menuOnly && this.mode !== 'menu'))
          .filter(action =>
            action.showAction ? action.showAction(this.item) : true,
          ).filter(action => {
            if (!action.flags) return true;
            const pass = action.flags.every(flag => {
              // Add new flags here
              switch (flag) {
                case 'internal':
                  return this.isInternal;

                default:
                  throw new Error('Unknown flag');
              }
            });
            return pass;
          })
          .filter(action => {
            let pass;

            if (!action.permissions) return true;
            if (action.permissions.all) {
              pass = action.permissions.all.every(perm =>
                this.hasPermission(perm),
              );
            }
            if (action.permissions.some) {
              pass = action.permissions.some.some(perm =>
                this.hasPermission(perm),
              );
            }
            return pass;
          })
          .map(action => ({
            ...action,
            disabled: action.disabled ? action.disabled(this.item) : false,
          })),
      ];

      if (this.showArchiveRestore) {
        actions.push(
          {
            divider: true,
          },
          {
            catch: true, // First parent component should handle this command
            command: 'archive',
            label: this.archiveView ? 'Restore' : 'Archive',
            icon: this.archiveView ? 'inbox-out' : 'archive',
            type: 'danger',
          },
        );
      }

      return actions;
    },
  },

  methods: {
    buttonClass(action, prevAction) {
      return {
        button: true,
        divider: prevAction && prevAction.divider,
      };
    },

    menuItemClass(action) {
      return action.type === 'danger' ? 'danger-item' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.dropdown-button {
  background: transparent;
  border: none;

  &:hover,
  &:focus {
    background: none;
  }
}

.danger-item {
  color: $--color-danger;
}

.el-button {
  width: 100%;
  text-align: left;
}

.button:not(:last-of-type) {
  margin-bottom: 0.4em;
}

.divider {
  border-top: $--border-base;
  margin-top: 1em;
  padding-top: 1em;
}
</style>
